import React from 'react';
import SEO from '../../components/seo';
import styles from './job.module.css';

const FinančníController = () => {
  return (
    <>
      <SEO title="Finanční controller" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Finanční controller</h1>

          <p className={styles.text}>
            NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst). 
          </p>
          <p className={styles.text}>
            Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v České republice, která poskytuje personalizované jídelníčky s kompletním servisem.
          </p>
          <p className={styles.text}>
            Jestli chceš být součástí nadšeného týmu a rozšířit řady úspěšného FoodTech startupu, tak čti dál! 
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              dohled nad financováním a průběžné sledování čerpání rozpočtů
            </li>
            <li className={styles.newList}>
              sběr a analýza aktuálních a budoucích peněžních toků
            </li>
            <li className={styles.newList}>
              příprava finančního plánu s ohledem na očekávané potřeby a zdroje
            </li>
            <li className={styles.newList}>
               řízení správy pohledávek
            </li>
            <li className={styles.newList}>
              příprava pravidelných a ad hoc ekonomických reportů/analýz
            </li>
            <li className={styles.newList}>
              spolupodílení se na přípravě finančního plánu, sestavení rozpočtu, sledování jeho plnění (bilance, výsledovky)
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Zkušenost v oblasti finančního controllingu/reportingu alespoň 3 roky
            </li>
            <li className={styles.newList}>
              VŠ vzdělání ekonomického směru (min. Bc)
            </li>
            <li className={styles.newList}>
              Pokročilá znalost Google Sheets/Excel
            </li>
            <li className={styles.newList}>
              Jsi pečlivá a analytická osoba
            </li>
            <li className={styles.newList}>
              Znalost anglického jazyka
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              motivační finanční ohodnocení a měsíční bonus
            </li>
            <li className={styles.newList}>
              reporting přímo zakladateli. možnost ovlivnit chod věcí
            </li>
            <li className={styles.newList}>
              možný kariérní růst až na CFO
            </li>
            <li className={styles.newList}>
              plně hrazené vzdělávací kurzy a konzultace s předními odborníky v oboru
            </li>
            <li className={styles.newList}>
              firemní obědy zdarma
            </li>
            <li className={styles.newList}>
              multisport kartu plně hrazenou firmou
            </li>
            <li className={styles.newList}>
              vodu, kávu, čaj, džusy, čerstvé ovoce na pracovišti dle libosti
            </li>
            <li className={styles.newList}>
              moderní kanceláře v srdci Karlína
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
        </div>
      </div>
    </>
  )
}

export default FinančníController